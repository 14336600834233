import React, { Component } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

//icons

import BackArrow from "../../icons/back-arrow.svg"
import Logo from "../../icons/logo.svg"

//helpers

import { desktopSize, mobileSize } from "../../helpers/sizeCalculations"

// Nav
const Nav = styled.div`
  margin-top: -6px;
  a {
    text-decoration: none;
  }

  .back-arrow {
    margin-top: 5px;
    display: inline-block;
  }

  .active {
    text-decoration: underline;
  }
`

// Logo

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${mobileSize(42.2)};

  svg {
    width: 11.25vw;
    height: auto;
    display: block;
    @media screen and (min-width: 768px) {
      max-height: ${desktopSize(49)};
    }
  }

  .svg-fill {
    fill: ${props => props.theme.color.pink};
  }
`

const BackArrowIcon = styled(BackArrow)`
  max-width: ${desktopSize(40)};
`

const BackArrowIconWrapper = styled.span`
  display: inline-block;
  line-height: 0;
`

const SHARED_NAV_CSS = css`
  display: block;
  color: ${props => props.theme.color.pink};
  text-decoration: none;
  font-size: ${desktopSize(21)};
  line-height: 1.52;
  font-family: "gt-walsheim-regular";
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const NavItem = styled(props => <Link {...props} />)`
  ${SHARED_NAV_CSS}
`

const LogoLink = styled(props => <Link {...props} />)`
  position: absolute;
  left: 30px;
  bottom: 30px;
  max-height: 7vh;
`

const Back = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const BackText = styled.span`
  margin-left: 12px;
  display: inline-block;
  vertical-align: top;
  font-size: ${desktopSize(21)};
  font-family: "gt-walsheim-regular";
  line-height: 0;
  &:hover {
    text-decoration: underline;
  }
`

const MenuWrapper = styled.div``

/**
 * Component Structure
 */
class Menu extends Component {
  constructor(props) {
    super(props)

    // Refs
    this.nav = React.createRef()

    //nav details - title with link
    this.navDetails = [
      {
        title: "Larry Ellison's story",
        link: "/larry-ellisons-story",
      },
      {
        title: "Current Investments",
        link: "/current-investments",
      },
      {
        title: "Previous Investments",
        link: "/previous-investments",
      },
    ]
  }

  backRedirectionHandler = e => {
    if (this.props.page === "investment") {
      const allInvestmentPosts = this.props.data.allWordpressWpInvestment.nodes
      if (!allInvestmentPosts[0]) return

      let url = window.location.href.split("="),
        slug = url[1],
        exactSlug = slug.split("&")[0],
        firstPostSlug = allInvestmentPosts[0].custom.slug

      //check if the first post is active or not
      if (exactSlug !== firstPostSlug) {
        e.preventDefault()
        window.history.pushState(
          null,
          null,
          `${this.props.path}?slug=${firstPostSlug}`
        )
        this.props.setInvestmentBySlug()
      }
    }
  }

  render() {
    const NAV_LINKS = this.navDetails.map((detail, key) => {
      let activeState = false
      if (detail.link === this.props.uri) activeState = true
      return (
        <NavItem
          className={activeState ? "active" : null}
          key={key}
          to={detail.link}
          title={detail.title}
        >
          {detail.title}
        </NavItem>
      )
    })

    let BACK_BUTTON = this.props.showBackButton ? (
      <Link className="back-arrow" onClick={this.backRedirectionHandler} to="/">
        <Back>
          <BackArrowIconWrapper>
            <BackArrowIcon />
          </BackArrowIconWrapper>
          <BackText>Back</BackText>
        </Back>
      </Link>
    ) : null

    return (
      <MenuWrapper>
        <Nav ref={this.nav}>
          {NAV_LINKS}
          {BACK_BUTTON}
        </Nav>
        <LogoLink to="/" className="main-site-logo">
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </LogoLink>
      </MenuWrapper>
    )
  }
}

export default Menu
