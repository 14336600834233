//lib
import React, { Component } from "react"
import styled, { css } from "styled-components"
import { TweenMax } from "gsap/TweenMax"
import { TweenLite } from "gsap/TweenLite"
import { debounce } from "lodash"
import SwipeSlider from "./Slider"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import vhCheck from "vh-check"

//components
import StickyNav from "../global/StickyNav"
import Image from "./Image"
import ContentWrapper from "./Content"
import Menu from "../global/Menu"
import { mobileSize } from "../../helpers/sizeCalculations"
import { isDesktop } from "../../helpers/mediaQueryChecks"
import BezierEasing from "bezier-easing"
import CookieConsent from "../global/CookieConsent"

const plugins = [ScrollToPlugin] // eslint-disable-line no-unused-vars

/**
 * Styled Components CONSTANTS
 */
const PageLayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  color: ${props => props.theme.color.pink};
  overflow-y: hidden;
  .content-container::-webkit-scrollbar {
    width: 0 !important;
  }
  .content-wrapper {
    position: relative;
  }

  .cross-fade-slide {
    &.cross-fade-slide-enter {
      opacity: 0;
    }
    &.cross-fade-slide-enter-active {
      opacity: 1;
      transition: opacity 1s ease-in-out;
    }
    &.cross-fade-slide-exit {
      opacity: 1;
    }
    &.cross-fade-slide-exit-active {
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
  }

  .fadeIn {
    opacity: 0;
    visibility: hidden;
  }

  ${props =>
    props.initialSlide &&
    `
    .initial-slide {
      opacity: 1;
      visibility: visible;
    }
    `}
`

const DisplayInlineBlock = css`
  display: inline-block;
  vertical-align: top;
`

const ImageWrapper = styled.section`
  ${DisplayInlineBlock}
  width: 41vw;
  height: 100%;
  visibility: hidden;
  position: relative;
  word-break: break-all;
  .gatsby-image-wrapper {
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const ContentContainer = styled.div`
  ${DisplayInlineBlock}
  width: calc(100% - 41vw - 18.5vw);
  height: calc(100vh - ${props => props.browserBarHeight}px);
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox 64 */
  position: relative;
  outline: none;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const WhiteDivider = styled.div`
  height: calc(100% - 30px);
  border-right: 2px solid #fff;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 10;
  @media screen and (max-width: 768px) {
    height: ${props => {
      const WIDTH = `${mobileSize(props.responsiveDividerYOffset)} - ${
        props.dividerHeight ? `${props.dividerHeight}px` : "30px"
      }`
      return `calc(100% - ${WIDTH})`
    }};
    top: ${props => mobileSize(props.responsiveDividerYOffset)};
    right: 15px;
    visibility: ${props =>
      props.showDividerInResponsive ? "visible!important" : "hidden!important"};
  }
`

const MenuContainer = styled.div`
  ${DisplayInlineBlock}
  width: 18.5vw;
  height: 100%;
  padding-top: 30px;
  padding-left: 30px;
  position: relative;
  visibility: hidden;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const PageTitle = styled.h3`
  padding-right: 30px;
  margin: 0;
  position: absolute;
  bottom: 30px;
  left: 30px;
  @media (max-width: 768px) {
    display: none;
    background: red;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    bottom: 55px;
  }
`

const ContentChildrenWrapper = styled.div`
  visibility: hidden;
`

/**
 * Component Structure
 */
class PageLayout extends Component {
  constructor(props) {
    super(props)
    this.pageBannerImage = React.createRef()
    this.pageContentContainer = React.createRef()
    this.pageMenu = React.createRef()
    this.divider = React.createRef()
    this.contentContainerRef = React.createRef()

    this.state = {
      scrolledPastInitial: false,
      scrollDirection: "down",
      imageList: (Array.isArray(props.image) && props.image) || [],
      browserBarHeight: 0,
    }

    // Method Declaration
    this.handleScroll = debounce(this.handleScroll.bind(this), 30)
  }

  componentDidMount() {
    this.contentContainerRef && this.contentContainerRef.current.focus()
    this.prevSlide = document.querySelector(".fadeIn")

    //set initial slide before scroll
    this.onPageEnterExitAnimate(true)
    this.setInitialSlideOnLoad(true)

    // Check viewport height.
    const vhInfo = vhCheck()
    this.setState({
      browserBarHeight: vhInfo.value,
    })
  }

  // get content title ref to animate
  getContentTitleRef = contentTitleRef =>
    (this.contentTitleRef = contentTitleRef)

  componentDidUpdate(prevProps) {
    if (this.props.animationStatus !== prevProps.animationStatus) {
      switch (this.props.animationStatus) {
        case "entering":
          this.onPageEnterExitAnimate(true)
          break
        case "exiting":
          this.onPageEnterExitAnimate()
          break
        default:
      }
    }

    //set initial animation on readmore active for investment pages
    if (this.props.readMoreFlagStatus !== prevProps.readMoreFlagStatus) {
      this.setInitialSlideOnLoad()
      this.contentContainerRef && this.contentContainerRef.current.focus()
    }

    if (this.props.slideChange !== prevProps.slideChange) {
      // animate divider
      if (this.divider && this.divider.current && window.innerWidth <= 768) {
        let animateContainerCSS = [
          { opacity: 0 },
          {
            opacity: 1,
            delay: 1,
            visibility: "visible",
            ease: BezierEasing(0.25, 0.1, 0.25, 1),
          },
        ]
        TweenMax.fromTo(this.divider.current, 1, ...animateContainerCSS)
      }
    }

    if (this.props.image !== prevProps.image) {
      this.setState({
        imageList: (Array.isArray(this.props.image) && this.props.image) || [],
      })
    }
  }

  componentWillReceiveProps() {
    // set focus on container when back forth of pages
    this.contentContainerRef && this.contentContainerRef.current.focus()
  }

  // handle sticky nav on scroll
  handleScroll(event) {
    let scrollTop = event && event.target.scrollTop
    this.setState({
      scrolledPastInitial: scrollTop > 200 ? true : false,
      scrollDirection: scrollTop > 200 ? "down" : "up",
    })

    this.animateChangeImagesOnScroll(event)
  }

  setInitialSlideOnLoad(onMount) {
    // set delay on mount to prevent last image animated on scroll to persist
    // set initial state for animation
    TweenMax.set(".fadeIn", {
      autoAlpha: 0,
      delay: onMount ? 1 : 0,
    })

    TweenMax.set(".initial-slide", {
      autoAlpha: 1,
      delay: onMount ? 1 : 0,
    })

    !this.props.readMoreFlagStatus &&
      this.contentContainerRef &&
      this.contentContainerRef.current &&
      TweenLite.to(this.contentContainerRef.current, 0.5, {
        scrollTo: 0,
      })
  }

  /**
   * Animation which changes images based on the count of the images listed in the wrapper
   *
   */
  animateChangeImagesOnScroll = event => {
    // stop scroll animation on mobile devices and cross fade slides
    if (!isDesktop) return

    const SLIDE_ELEMENTS = document.querySelectorAll(".fadeIn")
    const TARGET = event && event.target
    const IMAGE_LIST = this.state.imageList

    if (!SLIDE_ELEMENTS || !TARGET || IMAGE_LIST.length === 1) {
      return
    }

    const SCROLL_HEIGHT = TARGET.scrollHeight / 2
    const SCROLL_TOP = TARGET.scrollTop

    let Percentage = Math.round((SCROLL_TOP / SCROLL_HEIGHT) * 100)
    let index = Math.floor(Percentage / 30)
    index =
      SLIDE_ELEMENTS.length - 1 < index ? SLIDE_ELEMENTS.length - 1 : index

    let nextSlide = SLIDE_ELEMENTS[index]
    if (this.prevSlide !== nextSlide && SLIDE_ELEMENTS[index]) {
      //fade on scroll
      TweenMax.to(".fadeIn", 1, {
        autoAlpha: 0,
      })

      TweenMax.to(SLIDE_ELEMENTS[index], 1, {
        autoAlpha: 1,
      })
      this.prevSlide = nextSlide
    }
  }

  /**
   *  animate page on enter
   *  @param {Boolean} enter - True for enter/ False for exit
   */
  onPageEnterExitAnimate = enter => {
    let animateContainerCSS
    let animateBannerCSS

    if (enter) {
      animateContainerCSS = [
        { opacity: 0 },
        {
          opacity: 1,
          delay: 1,
          visibility: "visible",
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
        },
      ]
      animateBannerCSS = [
        { opacity: 0, x: -900 },
        {
          opacity: 1,
          x: 0,
          delay: 1,
          visibility: "visible",
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
        },
      ]
    } else {
      animateContainerCSS = [
        { opacity: 1 },
        { opacity: 0, delay: 0.3, ease: BezierEasing(0.25, 0.1, 0.25, 1) },
      ]
      animateBannerCSS = [
        { opacity: 1, x: 0 },
        {
          opacity: 0.3,
          x: -900,
          delay: 0.5,
          ease: BezierEasing(0.25, 0.1, 0.25, 1),
        },
      ]
    }

    if (this.pageBannerImage.current) {
      TweenMax.fromTo(this.pageBannerImage.current, 1, ...animateBannerCSS)
    }

    if (this.pageContentContainer.current) {
      TweenMax.fromTo(
        this.pageContentContainer.current,
        enter ? 1 : 0.3,
        ...animateContainerCSS
      )
    }

    // animate divider
    if (this.divider && this.divider.current) {
      TweenMax.fromTo(this.divider.current, 1, ...animateContainerCSS)
    }

    // animate content title ref
    if (this.contentTitleRef && this.contentTitleRef.current) {
      TweenMax.fromTo(
        this.contentTitleRef.current,
        enter ? 1 : 0.3,
        ...animateContainerCSS
      )
    }

    if (this.pageMenu.current) {
      TweenMax.fromTo(this.pageMenu.current, 1, ...animateContainerCSS)
    }
  }

  render() {
    /* Check if the Image given here is the array or a single Image.
     * if the Image props is an array like for the pages team, History and investment it will map through all the Images.
     * If the Image props is a single element it will simply return the component.
     */
    let SlideImageList = []
    let RESPONSIVE_IMAGE_LIST = []
    let { imageList } = this.state

    let AnimateFirstSlideInvestment = elem => {
      return (
        <div key={0}>
          <TransitionGroup>
            <CSSTransition
              key={this.props.slideChange}
              timeout={1000}
              classNames={"cross-fade-slide"}
            >
              {elem}
            </CSSTransition>
          </TransitionGroup>
        </div>
      )
    }

    if (Array.isArray(imageList) && imageList.length) {
      // Loop throug array of Images which is passed as one of the props.
      let IMAGE_LIST = this.state.imageList || []

      SlideImageList = IMAGE_LIST.map((elem, key) => {
        let className = `${key === 0 ? ` fadeIn initial-slide` : "fadeIn"}`
        let fluid =
          elem.image.localFile && elem.image.localFile.childImageSharp.fluid
        fluid &&
          RESPONSIVE_IMAGE_LIST.push(
            <Image
              className="slide"
              key={key}
              filename={fluid}
              altText={elem.image.alt_text}
            />
          )

        return (
          fluid && (
            <Image
              className={className}
              key={key}
              filename={fluid}
              altText={elem.image.alt_text}
            />
          )
        )
      })
    } else {
      //check if the image is present. THis will prevent build from failing if there are no images present.
      let image =
        this.props.image &&
        this.props.image.localFile &&
        this.props.image.localFile.childImageSharp.fluid
      image &&
        SlideImageList.push(
          <Image
            key={0}
            filename={this.props.image.localFile.childImageSharp.fluid}
            altText={this.props.image.alt_text}
          />
        )
    }

    return (
      <PageLayoutWrapper
        initialSlide={this.props.page === "investment"}
        onScroll={e => {
          e.persist()
          this.handleScroll(e)
        }}
      >
        <ImageWrapper ref={this.pageBannerImage}>
          {AnimateFirstSlideInvestment(
            <div className={`cross-fade-slide`}>{SlideImageList}</div>
          )}
          <PageTitle className="page-title">{this.props.title}</PageTitle>
        </ImageWrapper>
        <StickyNav
          scrollDirection={this.state.scrollDirection}
          scrolledPastInitial={this.state.scrolledPastInitial}
          navColor={"#00000000"}
          stickyNavOnOtherPages={true}
        />
        <ContentContainer
          browserBarHeight={this.state.browserBarHeight}
          tabIndex={0}
          ref={this.contentContainerRef}
          className="content-container"
        >
          <div className="content-wrapper">
            <ContentWrapper
              subTitle={this.props.subTitle}
              content={this.props.content}
              {...this.props}
              getContentTitleRef={this.getContentTitleRef}
            >
              <ContentChildrenWrapper ref={this.pageContentContainer}>
                {!this.props.hideImageInResponsive &&
                RESPONSIVE_IMAGE_LIST.length &&
                this.props.page !== "investment" ? (
                  <SwipeSlider>{RESPONSIVE_IMAGE_LIST}</SwipeSlider>
                ) : null}

                {this.props.children}
                <WhiteDivider
                  showDividerInResponsive={this.props.showDividerInResponsive}
                  dividerHeight={this.props.dividerHeight}
                  responsiveDividerYOffset={this.props.responsiveDividerYOffset}
                  ref={this.divider}
                />
              </ContentChildrenWrapper>
            </ContentWrapper>
          </div>
        </ContentContainer>
        <MenuContainer ref={this.pageMenu} className="menu-container">
          <Menu {...this.props} />
        </MenuContainer>
        <CookieConsent />
      </PageLayoutWrapper>
    )
  }
}

export default PageLayout

PageLayout.defaultProps = {
  showDividerInResponsive: true,
  hideImageInResponsive: true,
  dividerHeight: "",
  responsiveDividerYOffset: 185,
}
