// Libraries

import React from "react"
import { navigate } from "gatsby"
import vhCheck from "vh-check"

// Components

import Logo from "../../icons/logo.svg"
import MenuIcon from "../../icons/menu.svg"
import CloseIcon from "../../icons/close.svg"

// Helpers

import { mobileSize } from "../../helpers/sizeCalculations"

// Styles

import styled from "styled-components"

/*-----------------------------
Styles
-----------------------------*/

const Wrapper = styled.div`
  display: none;
  position: fixed;
  top: ${props =>
    (props.scrollDirection === "down" && props.scrolledPastInitial) ||
    props.navIsForceHidden
      ? "-" + mobileSize(48)
      : 0};
  left: 0;
  z-index: 10;
  width: 100%;
  height: ${mobileSize(48)};
  padding: 0 ${mobileSize(12)};
  color: ${props => props.theme.color.white};
  background-color: ${props =>
    props.scrolledPastInitial ? props.navColor : "transparent"};
  /* transition: all 0.5s ease-in-out; */
  transition: ${props =>
    (props.scrollDirection === "down" && props.scrolledPastInitial) ||
    props.navIsForceHidden
      ? "all .3s ease-in-out"
      : "all .5s ease-in-out"};

  @media all and (max-width: 768px) {
    display: block;
  }
`

/*--- Nav Container ---*/

const Container = styled.div`
  display: flex;
  height: ${mobileSize(48)};
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0;
  /* transition: all 0.5s ease-in-out; */
  transition: ${props =>
    (props.scrollDirection === "down" && props.scrolledPastInitial) ||
    props.navIsForceHidden
      ? "all .3s ease-in-out"
      : "all .5s ease-in-out"};
  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${props =>
      props.scrolledPastInitial && !props.stickyNavOnOtherPages
        ? props.theme.color.white
        : "transparent"};
  }
`

// Logo

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: ${props =>
    props.scrolledPastInitial && !props.stickyNavOnOtherPages ? "1" : "0"};

  svg {
    display: block;
    width: ${mobileSize(100)};
    height: auto;
  }

  .svg-fill {
    fill: ${props => props.theme.color.white};
  }
`

// Menu Icon

const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    display: block;
    width: ${mobileSize(44)};
    height: auto;
  }

  .svg-fill {
    fill: ${props => props.theme.color.white};
  }
`

/*--- Overlay ---*/

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.overlayIsOpen ? 0 : "-100%")};
  width: 100%;
  height: 100vh;
  opacity: 1;
  z-index: 11;
  color: ${props => props.theme.color.pink};
  background-color: ${props => props.theme.color.burgundy};
  transition: all 1s ease-in-out;
  overflow-y: auto;

  /* hide overlay above mobile devices screen */
  @media (min-width: 769px) {
    display: none;
  }
`

// Overlay Content - Used as a wrapper so we can trigger the opacity of the content in the overlay after the overlay has revealed itself

const OverlayContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  opacity: ${props => (props.overlayIsOpen ? 1 : 0)};
  transition: all 1s ease-in-out
    ${props => (props.overlayIsOpen ? ".5s" : "0s")};
  @media all and (max-width: 768px) and (orientation: landscape) {
    height: 150vw;
  }
`

// Primary Linklist

const LinkListPrimary = styled.div`
  position: absolute;
  top: ${mobileSize(30)};
  left: ${mobileSize(12)};
  font-size: ${mobileSize(30)};
  line-height: 1;
  font-family: "gt-sectra-fine-regular";
`

const LinkListPrimaryItem = styled.div`
  display: block;
  text-decoration: none;
  margin-bottom: ${mobileSize(20)};
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
`

// Secondary Linklist

const LinkListSecondary = styled.div`
  position: absolute;
  bottom: calc(${mobileSize(30)} + ${props => props.browserBarHeight}px);
  left: ${mobileSize(12)};
  font-size: ${mobileSize(18)};
  line-height: 1;
  font-family: "gt-walsheim-regular";
`

const LinkListSecondaryItem = styled.div`
  display: block;
  text-decoration: none;
  margin-bottom: ${mobileSize(20)};
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
`

// Copyright

const Copyright = styled.div`
  position: absolute;
  bottom: calc(${mobileSize(30)} + ${props => props.browserBarHeight}px);
  right: ${mobileSize(16)};
  font-size: ${mobileSize(18)};
  line-height: 1;
  font-family: "gt-walsheim-regular";
`

// Close

const CloseIconWrapper = styled.div`
  position: absolute;
  right: ${mobileSize(16)};
  top: ${mobileSize(11)};
  cursor: pointer;

  svg {
    display: block;
    width: ${mobileSize(32)};
    height: auto;
  }

  .svg-fill {
    fill: ${props => props.theme.color.white};
  }
`

/*-----------------------------
Component
-----------------------------*/

class StickyNav extends React.Component {
  constructor(props) {
    super(props)
    // State
    this.state = {
      overlayIsOpen: false,
      browserBarHeight: 0,
      navIsForceHidden: false,
    }
  }

  componentDidMount() {
    // Check viewport height.
    const vhInfo = vhCheck()
    this.setState({
      browserBarHeight: vhInfo.value,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.animationStatus !== prevProps.animationStatus) {
      switch (this.props.animationStatus) {
        // If a page is exiting then hide the nav.
        case "exiting":
          this.setState({
            navIsForceHidden: true,
          })
          break
        default:
      }
    }
  }

  /*-----------------------------
  Methods
  -----------------------------*/

  // Toggle Overlay

  toggleOverlay = () => {
    this.setState({
      overlayIsOpen: !this.state.overlayIsOpen,
    })
  }

  // Go To Page

  goToPage = pageHandle => {
    // Toggle the overlay. Primary use case is to hide it so we can see the page content we are navigating to.
    this.toggleOverlay()
    // Navigate to the page.
    navigate(pageHandle)
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    return (
      <>
        <Wrapper
          navColor={this.props.navColor}
          overlayIsOpen={this.state.overlayIsOpen}
          scrollDirection={this.props.scrollDirection}
          navIsForceHidden={this.state.navIsForceHidden}
          scrolledPastInitial={this.props.scrolledPastInitial}
        >
          <Container
            stickyNavOnOtherPages={this.props.stickyNavOnOtherPages}
            scrolledPastInitial={this.props.scrolledPastInitial}
          >
            <LogoWrapper
              stickyNavOnOtherPages={this.props.stickyNavOnOtherPages}
              scrolledPastInitial={this.props.scrolledPastInitial}
            >
              <Logo />
            </LogoWrapper>
            <MenuIconWrapper onClick={this.toggleOverlay}>
              <MenuIcon />
            </MenuIconWrapper>
          </Container>
        </Wrapper>
        <Overlay overlayIsOpen={this.state.overlayIsOpen}>
          <OverlayContent overlayIsOpen={this.state.overlayIsOpen}>
            <LinkListPrimary>
              <LinkListPrimaryItem
                onClick={e => this.goToPage("/larry-ellisons-story", e)}
              >
                Larry Ellison's story
              </LinkListPrimaryItem>
              <LinkListPrimaryItem
                onClick={e => this.goToPage("/current-investments", e)}
              >
                Current Investments
              </LinkListPrimaryItem>
              <LinkListPrimaryItem
                onClick={e => this.goToPage("/previous-investments", e)}
              >
                Previous Investments
              </LinkListPrimaryItem>
              <LinkListPrimaryItem
                onClick={e => this.goToPage("/contact-us", e)}
              >
                Contact Us
              </LinkListPrimaryItem>
              <LinkListPrimaryItem onClick={e => this.goToPage("/", e)}>
                Home
              </LinkListPrimaryItem>
            </LinkListPrimary>
            <LinkListSecondary browserBarHeight={this.state.browserBarHeight}>
              <LinkListSecondaryItem
                onClick={e => this.goToPage("/terms-of-use", e)}
              >
                Terms of use
              </LinkListSecondaryItem>
              <LinkListSecondaryItem
                onClick={e => this.goToPage("/privacy-policy", e)}
              >
                Privacy policy
              </LinkListSecondaryItem>
            </LinkListSecondary>
            <Copyright browserBarHeight={this.state.browserBarHeight}>
              © Copyright 2019
            </Copyright>
            <CloseIconWrapper onClick={this.toggleOverlay}>
              <CloseIcon />
            </CloseIconWrapper>
          </OverlayContent>
        </Overlay>
      </>
    )
  }
}

export default StickyNav
