import React, { Component } from "react"
import styled from "styled-components"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { mobileSize } from "../../helpers/sizeCalculations"
import { delay } from "lodash"

//slider
const ResponsiveImageContainer = styled.div`
  @media screen and (max-width: 768px) {
    width: ${mobileSize(368)};
    margin: 0 0 0 -6px;
    overflow: hidden;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
`

const ResponsiveImageWrapper = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: inline-block;
    width: ${mobileSize(368)};
    height: ${mobileSize(597)};
    position: relative;
    overflow: hidden;
    .gatsby-image-wrapper {
      height: 100%;
    }
    .swiper-container {
      height: 100%;
    }
    .slide {
      position: static;
    }
  }
`

export default class SwipeSlider extends Component {
  state = {
    animateContainer: false,
  }

  componentDidMount() {
    delay(() => {
      this.setState({
        animateContainer: true,
      })
    }, 500)
  }

  render() {
    return (
      <ResponsiveImageContainer
        className={this.state.animateContainer ? "show" : ""}
      >
        <ResponsiveImageWrapper ref={this.slider}>
          {this.props.children.length <= 1 ? (
            this.props.children
          ) : (
            <Swiper shouldSwiperUpdate={true}>{this.props.children}</Swiper>
          )}
        </ResponsiveImageWrapper>
      </ResponsiveImageContainer>
    )
  }
}
