import React, { Component } from "react"
import styled from "styled-components"
import ReactHtmlParser from "react-html-parser"

/**
 * Styles Constants
 */

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  visibility: hidden;
`

const ContentTitle = styled.h2`
  width: 80%;
  margin: -5px auto 0;
  text-align: center;
  font-weight: normal;
  line-height: 1.1;
  p {
    font-size: inherit;
    font-family: inherit;
    margin-bottom: 0;
  }
`

const PropsChildrenWrapper = styled.div``
const Container = styled.div`
  padding: 0 30px;

  @media screen and (max-width: 768px) {
    padding: 0 15px 0 13px;
  }
`
// component structure

class ContentLayout extends Component {
  constructor(props) {
    super(props)
    this.contentTitleRef = React.createRef()
  }

  componentDidMount() {
    //get component ref of content title to animate
    this.props.getContentTitleRef(this.contentTitleRef)
  }

  render() {
    const pageContentTitle = this.props.subTitle

    return (
      <Container>
        {pageContentTitle && (
          <ContentWrapper
            ref={this.contentTitleRef}
            className={this.props.headerClassName}
          >
            <ContentTitle>{ReactHtmlParser(pageContentTitle)}</ContentTitle>
          </ContentWrapper>
        )}
        <PropsChildrenWrapper id="trigger">
          {this.props.children}
        </PropsChildrenWrapper>
      </Container>
    )
  }
}

ContentLayout.defaultProps = {
  headerClassName: "",
}

export default ContentLayout

ContentLayout.defaultProps = {
  subTitleClassName: "",
}
