import React, { Component } from "react"
import styled from "styled-components"
import { TweenLite } from "gsap/TweenLite"

//Components
import { mobileSize, desktopSize } from "../../helpers/sizeCalculations"
import { Link } from "gatsby"
import { delay } from "lodash"

// Styles
const CookieConsentWrapper = styled.div`
  width: 100%;
  padding: 26px 0 26px 10.3%;
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 11;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: ${props => props.theme.color.pink};
  font-family: "gt-walsheim-regular";

  @media screen and (max-width: 768px) {
    padding: 26px 3.7%;
    flex-direction: column;
    text-align: center;
  }
`

const Title = styled.h6`
  margin-right: 3.1%;
  flex-basis: 14.1%;
  font-family: inherit;
  font-size: ${desktopSize(19)};
  text-transform: uppercase;
  letter-spacing: 0.59px;

  @media screen and (max-width: 768px) {
    width: 79%;
    margin: 0 auto 25px;
    font-size: ${mobileSize(19)};
  }
`

const Content = styled.p`
  margin: 0 2.7% 0 0;
  flex-basis: 52.3%;
  font-family: inherit;
  font-size: ${desktopSize(18)};
  line-height: 1.22;
  letter-spacing: 1px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 0 25px;
    font-size: ${mobileSize(18)};
    letter-spacing: normal;
  }

  a {
    font-family: inherit;
    text-decoration: underline;
  }
`

const CookieLink = styled.span`
  font-family: inherit;
  letter-spacing: 0.8px;
  text-decoration: underline;
  cursor: pointer;
  display: block;

  @media screen and (max-width: 768px) {
    font-size: ${mobileSize(18)};
    letter-spacing: normal;
  }
`

const Wrapper = styled.div`
  text-align: center;
  a {
    display: inline-block;
    padding-top: ${desktopSize(12)};
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    font-size: ${mobileSize(18)};
    a {
      display: inline-block;
      padding-top: ${mobileSize(12)};
    }
  }
`

class CookieConsent extends Component {
  constructor(props) {
    super(props)

    // initiate window object
    this.state = {
      windowNode: null,
      doneLoading: false,
    }
  }

  componentDidMount() {
    this.setState({
      windowNode: window,
    })

    delay(this.activateFlag, 500, false)
  }

  //To activate Flag once the delay is over.
  activateFlag = () => this.setState({ doneLoading: true })

  //function to set cookie and hide the component
  cookieHandler = () => {
    let container = document.querySelector(".cookie-consent-wrapper")
    let documentNode = this.state.windowNode && this.state.windowNode.document
    if (documentNode) {
      documentNode.cookie = "gatsby-gdpr-google-analytics=" + true + ";"
    }
    TweenLite.to(container, 3, { bottom: "-100%" })
  }

  //to check if the cookie is already present
  checkCookie = () => {
    let name = "gatsby-gdpr-google-analytics=true"
    let documentNode = this.state.windowNode && this.state.windowNode.document
    if (documentNode) {
      let decodedCookie = document.cookie
      let ca = decodedCookie.split(";")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return false
        }
      }
    }
    return true
  }

  render() {
    let ContentDetals =
      this.checkCookie() && this.state.doneLoading ? (
        <CookieConsentWrapper className="cookie-consent-wrapper">
          <Title>please review our privacy and cookie notice</Title>
          <Content>
            This site uses cookies to store information on your computer or
            device. By continuing to use this site, you agree to the placement
            of these cookies.
          </Content>
          <Wrapper>
            <CookieLink onClick={this.cookieHandler}>OK</CookieLink>
            <Link to="/privacy-policy/" title="Learn More">
              Learn More
            </Link>
          </Wrapper>
        </CookieConsentWrapper>
      ) : null
    return ContentDetals
  }
}

export default CookieConsent
