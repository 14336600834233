import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"

/**
 * Styles Constants
 */
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

class ImageLayout extends Component {
  render() {
    const { filename, className, altText } = this.props
    return (
      <ImageWrapper className={className}>
        <Img alt={altText} fluid={filename} />
      </ImageWrapper>
    )
  }
}

//Validation of the Image prop.
ImageLayout.propTypes = {
  filename: PropTypes.object,
}

export default ImageLayout
